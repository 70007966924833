import React, { useEffect, useState } from "react";
import logo from "../../assets/images/footerLogo.svg";


const NavBarDark2 = () => {

  return (
    <>
      <nav className="w-full border-b border-[#4B4B4B33] sticky -top-1 z-[999] backdrop-blur-md bg-green-500 bg-opacity-100">
        <div className="container mx-auto h-[10vh] px-4 md:px-10 lg:px-4 flex justify-between items-center">
          <a
            className=" w-[100px] sm:w-[120px] md:w-[150px] lg:w-[120px] xl:w-[150px] hover:scale-105 transition-all duration-300"
            href="/"
          >
            <img src={logo} alt="logox" />
          </a>

        </div>
      </nav>
    </>
  );
};

export default NavBarDark2;
