import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import HOME from "./pages/HOME";
import "./App.css";
import "aos/dist/aos.css";

import { useEffect } from "react";
function App() {
  const location = useLocation();

  useEffect(() => {
    openApp()
  }, [])



  function openApp() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // URLs for App Store and Play Store
    var appStoreUrl = 'https://apps.apple.com/us/app/Venco-resident/id1542447397';
    var playStoreUrl = 'https://play.google.com/store/apps/details?id=com.manqala.estatemanager';
  
    // Check if the user is on an iOS or Android device
    if (/android/i.test(userAgent)) {
      // Redirect to Google Play Store
      window.location.href = playStoreUrl;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redirect to App Store
      window.location.href = appStoreUrl;
    } else {
      // Fallback if the platform is not recognized
      alert('Please open this link on your mobile device.');
    }
  }
  
  return (
    <>
      {
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HOME />} />           
            <Route path="*" element={<HOME />} />
          </Routes>
        </AnimatePresence>
      }
    </>
  );
}

export default App;
